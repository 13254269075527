import styled, { css } from 'styled-components';

import { H2, Text1Bold } from 'src/components/Text';
import { ErrorNotificationWrapper } from 'src/components/ErrorNotification/error-notification-styled';
import { smMedia, mdMedia, themeColor } from 'src/theme/utils';

export const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 144px);

  &::after {
    content: '';
    background-image: url(/images/bestsnack/mobile-bg@2x.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  ${smMedia(css`
    min-height: calc(100vh - 236px);

    &::after {
      background-image: url(/images/bestsnack/bg@2x.png);
    }
  `)}

  ${mdMedia(css`
    min-height: calc(100vh - 144px);
  `)}
`;

export const Container = styled.div`
  max-width: 100%;

  ${mdMedia(css`
    max-width: 504px;
    margin: 0 auto;
  `)}

  & ${ErrorNotificationWrapper} {
    margin: 0;
  }
`;

export const Title = styled(H2)`
  text-align: center;
  margin-bottom: 16px;

  ${mdMedia(css`
    font-size: 44px;
    line-height: 52px;
  `)}
`;

export const Subtitle = styled(Text1Bold)`
  text-align: center;
  margin-bottom: 24px;
`;

export const Button = styled.button`
  width: 100%;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 14px;
  border-radius: 8px;
  margin-bottom: 32px;
  background-color: ${themeColor('primary50')};
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: ${themeColor('black0')};

  &[disabled] {
    cursor: not-allowed;
  }
`;

export const PrivacyWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const PrivacyCheckbox = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 0.5rem;
  cursor: pointer;

  & svg > path {
    stroke: transparent;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  & svg > rect {
    stroke: ${themeColor('black20')};
    stroke-width: 2px;
  }

  &.checked {
    & svg > path {
      stroke: ${themeColor('primary60')};
    }

    & svg > rect {
      stroke: transparent;
    }
  }
`;

export const EmailConfirmWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const EmailConfirmCheckbox = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 0.5rem;
  cursor: pointer;

  & svg > path {
    stroke: transparent;
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  & svg > rect {
    stroke: ${themeColor('black20')};
    stroke-width: 2px;
  }

  &.checked {
    & svg > path {
      stroke: ${themeColor('primary50')};
    }

    & svg > rect {
      stroke: transparent;
    }
  }
`;
